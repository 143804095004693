/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** Black */
@font-face {
    font-family: 'SF Display';
    font-weight: 900;
    src: url('./fonts/SF-Pro-Display-Black.otf');
}

/** Bold */
@font-face {
    font-family: 'SF Display';
    font-weight: 700;
    src: url('./fonts/SF-Pro-Display-Bold.otf');
}

/** Heavy */
@font-face {
    font-family: 'SF Display';
    font-weight: 800;
    src: url('./fonts/SF-Pro-Display-Heavy.otf');
}

/** Light */
@font-face {
    font-family: 'SF Display';
    font-weight: 200;
    src: url('./fonts/SF-Pro-Display-Light.otf');
}

/** Medium */
@font-face {
    font-family: 'SF Display';
    font-weight: 500;
    src: url('./fonts/SF-Pro-Display-Medium.otf');
}

/** Regular */
@font-face {
    font-family: 'SF Display';
    font-weight: 400;
    src: url('./fonts/SF-Pro-Display-Regular.otf');
}

/** Semibold */
@font-face {
    font-family: 'SF Display';
    font-weight: 600;
    src: url('./fonts/SF-Pro-Display-Semibold.otf');
}

/** Thin */
@font-face {
    font-family: 'SF Display';
    font-weight: 300;
    src: url('./fonts/SF-Pro-Display-Thin.otf');
}

/** Ultralight */
@font-face {
    font-family: 'SF Display';
    font-weight: 100;
    src: url('./fonts/SF-Pro-Display-Ultralight.otf');
}

/*---------------------------------------------------------------------------*
 * SF UI Text
 *---------------------------------------------------------------------------*/

/** Bold */
@font-face {
    font-family: 'SF Text';
    font-weight: 700;
    src: url('./fonts/SF-Pro-Text-Bold.otf');
}

/** Bold Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 700;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-BoldItalic.otf');
}

/** Heavy */
@font-face {
    font-family: 'SF Text';
    font-weight: 800;
    src: url('./fonts/SF-Pro-Text-Heavy.otf');
}

/** Heavy Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 800;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-HeavyItalic.otf');
}

/** Light */
@font-face {
    font-family: 'SF Text';
    font-weight: 200;
    src: url('./fonts/SF-Pro-Text-Light.otf');
}

/** Light Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 200;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-LightItalic.otf');
}

/** Medium */
@font-face {
    font-family: 'SF Text';
    font-weight: 500;
    src: url('./fonts/SF-Pro-Text-Medium.otf');
}

/** Medium Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 500;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-MediumItalic.otf');
}

/** Regular */
@font-face {
    font-family: 'SF Text';
    font-weight: 400;
    src: url('./fonts/SF-Pro-Text-Regular.otf');
}

/** Regular Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 400;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-RegularItalic.otf');
}

/** Semibold */
@font-face {
    font-family: 'SF Text';
    font-weight: 600;
    src: url('./fonts/SF-Pro-Text-Semibold.otf');
}

/** Semibold Italic */
@font-face {
    font-family: 'SF Text';
    font-weight: 600;
    font-style: italic;
    src: url('./fonts/SF-Pro-Text-SemiboldItalic.otf');
}
